import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';

class ContactThree extends Component {
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <center>
                                <h1 className="theme-gradient">Booking Info</h1>
                                <div className="im_address_inner">
                                <div className="im_address mt--5">
                                        <span>Interested in booking Kora Sline for an event?</span> <br></br>
                                        <span><Link to="#calendar">Click here to check availability</Link></span>
                                    </div>
                                    <div className="im_address mt--5">
                                        <span>To email directly:</span>
                                        <a className="link im-hover" href="mailto:bookings@korasline.com">bookings@korasline.com</a>
                                    </div>
                                </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;