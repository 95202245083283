import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import TabWigs from "../elements/tab/TabWigs";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
// import { Link } from 'react-router-dom';
import TabThree from "../elements/tab/TabThree";
import Calendar from "../elements/calendar/Calendar";
import { datadogRum } from '@datadog/browser-rum';
import BrandTwo from "../elements/BrandTwo";
import Instagram from "../elements/insta/Instagram";
import { HashLink as Link } from 'react-router-hash-link';
import {FaInstagram, } from "react-icons/fa";

const SocialShare = [
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/Korasline'},
]
    
datadogRum.init({
    applicationId: '6f13e054-5d9c-4440-a2f2-b9859f611344',
    clientToken: 'pub7f7a70e4e24b9437c4c3af5670aa89a2',
    site: 'datadoghq.com',
    service:'kora-sline',
    env:'prd',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
});
    
datadogRum.startSessionReplayRecording();

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Cincinnati Drag Queen',
        description: '',
        buttonText: 'Booking Info',
        buttonLink: '#booking'
    }
]
const PortfolioLanding = () => {
    let title = 'About Kora Sline',
        description = 'Kora gives you everything from camp looks to 80’s Rock Star realness. With a heart of gold, you’ll fall in love with her energy and creative touch. A former cast member of The Cabaret in Cincinnati, you can find this entertainer all over the tri-state area. Kora also has experience hosting and creating her own shows and an amateur drag competition that ran for two years called The Chop. In her spare time, Kora loves to style wigs and create costumes. Catch Kora performing with Hard Candy productions and Le Moo Drag Brunch in Louisville monthly. With over 25 years of experience in the performance arts from theatre, dance, color guard, and vocal performance, this performer is the total package!';
    const PostList = BlogContent.slice(0 , 3);
    return (
        <div className="active-dark bg_color--9">
            <Helmet pageTitle="Kora Sline" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title theme-gradient">Kora Sline<br/>
                                            </h1>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            <div className="slide-btn mt--30"><Link className="btn-default btn-border btn-opacity" to="#booking">Booking Info</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--8">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="">
                                    <div className="thumbnail">
                                    <div className="section-title container">
                                            <center><h2 className="title mb--20 theme-gradient">{title}</h2>
                                            <p></p>
                                            <p className="description mt_dec--20">{description}</p></center>
                                        </div>
                                        <div className="row mt--20">
                            <div className="col-lg-12">
                            <center><h4 className="title theme-gradient">Regularly performing at these locations:</h4></center>
                                <BrandTwo />
                            </div>
                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            {/* End About Area */}
            {/* Calendar Start */}
            <div id="calendar" className="fix">
                <div className="calendar-area ptb--120 bg_color--9">
                    <div className="calendar-wrapper">
                        <div className="container">
                            <div className="">
                                <div className="">
                                    <div className="section-title text-center mb--50">
                                            <h2 className="title mb--20 theme-gradient">Upcoming Events</h2>
                                            <p className="description">To book Kora Sline for an event, please email <a className="link im-hover" href="mailto:wigs@korasline.com">bookings@korasline.com</a></p>
                                            <a target="_blank" className="px-btn px-btn-white" href="https://calendar.google.com/calendar/ical/dvcidqjia7p5j39qq43km7t5vs%40group.calendar.google.com/public/basic.ics">Click here to add Kora's events to your Calendar</a>
                                            </div>
                                            <div className="calendar set-background"><Calendar /></div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Calendar End */}           

            {/* OLD CALENDAR */}
            {/* <div id="calendar" className="fix">
                <div className="calendar portfolio-area pb--120 bg_color--8">
                    <div className="calendar">
                        <div className="calendar container">
                        <div className="section-title text-center mb--50">
                        <h2 className="title theme-gradient">Upcoming Events</h2>
                        <Calendar /></div>
                        </div>
                    </div>
                </div>
            </div> */}            

            {/* Photo + Videos Start */}
            <div id="photosvideos" className="fix">
                <div className="photosvideos-area ptb--120  bg_color--8">
                    <div className="photosvideos-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="">
                                    <div className="thumbnail">
                                    <div className="section-title text-center mb--50">
                                            <h2 className="title mb--20 theme-gradient">Photos and Videos</h2></div>
                                            <div className="wrapper plr--70 plr_sm--30 plr_md--30"><TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" /></div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Photo + Videos End */}

            {/* Wigs + Merch */}
            <div id="merchwigs" className="fix bg_color--9">
                <div className="merchwigs-area ptb--120 bg_color--9">
                    <div className="merchwigs-wrapper">
                        <div className="container">
                            <div className="">
                                <div className="">
                                    <div className="section-title text-center mb--50">
                                            <h2 className="title mb--20 theme-gradient">Plus Size Wigs</h2></div>
                                            <TabWigs column="col-lg-4 col-md-6 col-sm-6 col-12" /></div>
                        <div class="row"><div class="col-lg-12"><div class="single-column"><h4 class="tilte">Big wigs for a big world!</h4>
                    
                        
                        <p>Looking for a party pony or just some big drag hair, you’ve found the right place. Kora has 3+ years of hair styling experience and is ready to create the wig of your dreams!
                        For wig prices and commissions quotes, please email: <a className="link im-hover" href="mailto:wigs@korasline.com">wigs@korasline.com</a></p>
                        <p><a href="https://www.instagram.com/plussizewigs"><FaInstagram /> Plus Size Wigs Official Instagram</a></p>
                        </div></div>
                        
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Wigs End */} 

            <div id="merch" className="fix bg_color--8">
                <div className="merch portfolio-area pb--120 bg_color--8">
                    <div className="merch bg_color--8">
                        <div className="merch container bg_color--8">
                        <div className="section-title text-center mb--50 bg_color--8">
                        <h2 className="title theme-gradient">Kora's Merch Shop</h2><p></p>
                        {/* <h2 class="text-white mb--0">Enough Talk, Let's Build Something Together</h2> */}
                        <a class="btn-default btn-large btn-border btn-opacity" href="https://korasline.threadless.com/">Click here to view The Merch Shop</a></div>
                        
                        </div>
                    </div>
                </div>
                
            </div>


            {/* Start Blog Area */}
            {/* <div id="blog" className="fix">
                <div className="rn-blog-area pb--120 bg_color--8 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">My Latest Post</span>
                                    <h2 className="title">Latest News</h2>
                                    <p className="description">There are many variations of passages of Lorem Ipsum available, <br />but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12 mt--30" key={i}>
                                    <div className="im_box">
                                        <div className="thumbnail">
                                            <Link to="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="content_heading">
                                                    <div className="category_list">
                                                        <Link to="/portfolio-details">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title">
                                                        <Link to="/blog-details">{value.title}</Link>
                                                    </h4>
                                                </div>
                                                <div className="content_footer">
                                                    <Link to="/blog-details" className="rn-btn btn-opacity">Read More</Link>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to="/blog-details"></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
            </div> */}
            {/* End Blog Area */}


            <div id="booking" className="fix bg_color--9">
                <div className="booking portfolio-area pb--120 bg_color--9">
                    <div className="booking">
                        <div className="booking container">
                        <div className="section-title text-center mb--50">
                        <h2 className="title theme-gradient">Booking</h2><p></p>
                        <span>Interested in booking Kora Sline for an event?</span> <br></br>
                        <span><b><Link to="#calendar">Click here to check availability</Link></b></span>
                                    <div className="im_address mt--5">
                                        <span>To email directly:</span>
                                        <a className="link im-hover" href="mailto:bookings@korasline.com">bookings@korasline.com</a>
                                    </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Start COntact Area */}
            {/* <div id="booking" className="fix">
                <div className="rn-contact-area pb--120 bg_color--8">
                    <br></br>
                    <ContactThree contactImages="/assets/images/about/about-13.jpg" contactTitle="" />
                </div>
            </div> */}
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioLanding;
