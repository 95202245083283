import React from "react";
import FullCalendar from "@fullcalendar/react"
import googleCalendarPlugin from "@fullcalendar/google-calendar"
import listPlugin from '@fullcalendar/list';
import base64 from 'react-native-base64'


const Calendar = () => {
  const calendar = base64.decode("QUl6YVN5RGxTTGVHQlRHanNIVVc0YWNrZE5jUHktcDgyRnlwcjhv");

  return (
<>
<section id="calendar" className="calendar" >
        <div className="calendar container">
 <div>
          <div className="calendar">
            
            <div className="calendar">
            <FullCalendar
      plugins={[listPlugin, googleCalendarPlugin]}
      initialView="listYear"
      googleCalendarApiKey={calendar}
      events={{
        googleCalendarId:
          "dvcidqjia7p5j39qq43km7t5vs@group.calendar.google.com", // dvcidqjia7p5j39qq43km7t5vs@group.calendar.google.com
      }}
      displayEventTime={true}
      eventColor={"#f44880"}
      listDayFormat={{ // will produce something like "Tuesday, September 18, 2018"
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long'
      }}
      listDaySideFormat={false}
      height={600}
      contentHeight={600}
      expandRows={true}
      validRange={{
        start: new Date().toISOString().split("T")[0]
      }}/>
            </div>
          </div>
  </div>
  </div>
</section>
</>
  );
};

export default Calendar;
